<template>
   <v-card>
        <v-card-title class="text-h5 font-weight-bold natural--text text--darken-4">{{title}}</v-card-title>
          <v-card-text class="text-center">
            <apexchart type="bar" height="350" width="100%" :options="chartOptions" :series="series"></apexchart>
            </v-card-text>
        </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    series: {
      type: Array
    },
    label: {
      type: Array
    }
  },
  mounted () {
    // this.chartOptions.xaxis.categories = this.label
  },
  data () {
    return {
      // series: [{
      //   name: 'เขียว',
      //   data: [500, 800, 900, 950, 500, 939, 839, 600, 800],
      //   color: '#388E3C'
      // }, {
      //   name: 'เหลือง',
      //   data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      //   color: '#FBC02D'
      // }, {
      //   name: 'แดง',
      //   data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      //   color: '#D32F2F'
      // }],
      chartOptions: {
        chart: {
          type: 'bar',
          // stacked: true,
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top',
              total: {
                enabled: true
              }
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          // categories: ['หมู่ 1', 'หมู่ 2', 'หมู่ 3', 'หมู่ 4', 'หมู่ 5', 'หมู่ 6', 'หมู่ 7', 'หมู่ 8', 'หมู่ 9']
        },
        yaxis: {
          title: {
            text: 'จำนวนผู้ถูกคัดกรอง'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return ' ' + val + ' คน'
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
  .v-sheet.v-card {
    border-radius: 16px;
    /* border-color: #ecaf00 !important; */
  }
</style>
