<template>
   <v-card>
          <v-card-title class="text-h5 font-weight-bold natural--text text--darken-4">{{title}}</v-card-title>
          <v-card-text class="text-center">
              <!-- <v-img v-if="ranking.length === 0" width="88" class="mx-auto" :src="require('@/assets/rankingEmpty.svg')"/>
              <div v-if="ranking.length === 0" class="text-subtitle-1 natural--text">ยังไม่มีลำดับ</div> -->
              <v-row class="text-left" dense>
                <v-col cols="12" v-for="(item,index) in ranking" :key="index">
                    <template v-if="index===0">
                        <RankingListFirstItem :name="item.fullname" :position="item.position" :total="item.countRecords" :index="index" />
                    </template>
                    <template v-else>
                        <RankingListItem :name="item.fullname" :position="item.position" :total="item.countRecords" :index="index" />
                    </template>
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
</template>

<script>
import RankingListItem from '@/components/general/RankingListItem'
import RankingListFirstItem from '@/components/general/RankingListFirstItem'
export default {
  components: {
    RankingListFirstItem,
    RankingListItem
  },
  props: {
    title: {
      type: String
    },
    ranking: {
      type: Array,
      default: function () {
        return [
          { name: 'สมชาย ทองคำ', position: 'เจ้าหน้าที่', total: 1000 },
          { name: 'สมชาย ทองคำ', position: 'เจ้าหน้าที่', total: 1000 },
          { name: 'สมชาย ทองคำ', position: 'เจ้าหน้าที่', total: 1000 },
          { name: 'สมชาย ทองคำ', position: 'เจ้าหน้าที่', total: 1000 },
          { name: 'สมชาย ทองคำ', position: 'เจ้าหน้าที่', total: 1000 },
          { name: 'สมชาย ทองคำ', position: 'เจ้าหน้าที่', total: 1000 },
          { name: 'สมชาย ทองคำ', position: 'เจ้าหน้าที่', total: 1000 },
          { name: 'สมชาย ทองคำ', position: 'เจ้าหน้าที่', total: 1000 },
          { name: 'สมชาย ทองคำ', position: 'เจ้าหน้าที่', total: 1000 },
          { name: 'สมชาย ทองคำ', position: 'เจ้าหน้าที่', total: 1000 }
        ]
      }
    }
  }
}
</script>

<style scoped>
  .v-sheet.v-card {
    border-radius: 16px;
    /* border-color: #ecaf00 !important; */
  }
</style>
