<template>
   <v-card>
          <v-card-title class="text-h5 font-weight-bold natural--text text--darken-4">ผลลัพธ์แบบสำรวจ</v-card-title>
          <v-card-text >
              <div v-if="series.length === 0" class="text-subtitle-1 natural--text text--darken-5">ยังไม่มีข้อมูล</div>
              <v-row>
                  <v-col cols="4">
              <div id="chart">
               <apexchart v-if="show" type="donut" width="250" :options="chartOptions" :series="series"></apexchart>
              </div>
                  </v-col>
                  <v-col cols="8">
                      <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left text-subtitle-1 natural--text">
            แบบฟอร์ม
          </th>
          <th class="text-left text-subtitle-1 natural--text">
            ครั้ง
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
          :key="item.formTypeId"
        >
          <td class="text-subtitle-1 primary--text text--darken-4">{{ item.formTypeName }}</td>
          <td class="text-subtitle-1 primary--text text--darken-4">{{ item.countResponse }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
                  </v-col>
              </v-row>
              <!-- <v-img width="88" class="mx-auto" :src="require('@/assets/rankingEmpty.svg')"/> -->
          </v-card-text>
        </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    items: {
      type: Array
    }
  },
  mounted () {
    for (let i = 0; i < this.items.length; i++) {
      this.series.push(this.items[i].countResponse)
      this.chartOptions.labels.push(this.items[i].formTypeName)
    }
    this.$nextTick(() => {
      this.show = true
    })
  },
  watch: {
    items () {
      for (let i = 0; i < this.items.length; i++) {
        this.series.push(this.items[i].countResponse)
        this.chartOptions.labels.push(this.items[i].formTypeName)
      }
    }
  },
  data () {
    return {
      data: [
        { text: 'แบบฟอร์มโรคเรื้อรัง', value: '24' },
        { text: 'แบบฟอร์มยุงลาย', value: '24' },
        { text: 'แบบฟอร์มเด็กอายุน้อยกว่า 5 ปี', value: '24' },
        { text: 'แบบฟอร์มคนท้อง', value: '24' },
        { text: 'แบบฟอร์มคนพิการ', value: '24' }
      ],
      show: false,
      series: [],
      chartOptions: {
        labels: [],
        chart: {
          type: 'donut'
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          show: false,
          position: 'right',
          offsetY: 0,
          height: 600,
          fontFamily: 'Kanit,san serif',
          horizontalAlign: 'right',
          fontSize: '24px',
          formatter: function (val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex]
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 50
            }
          }
        }]
      }
    }
  }
}
</script>

<style scoped>
  .v-sheet.v-card {
    border-radius: 16px;
  }
</style>
