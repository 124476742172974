<template>
    <v-container fluid>
      <TitleBar :title="'วิเคราะห์'" :subtitle="''">
      </TitleBar>

      <FilterBar v-if="!preLoad">
      <template v-slot:left>
        <v-col cols="12" md="2" >
                <Select :title="'ตำบล'" :placeholder="'โปรดเลือก'" :items="subdistrictItems" v-model="subdistrict" item-text="subdistrict" return-object :disabled="dataLoading"/>
              </v-col>
        <v-col cols="12" md="2" >
                  <DatePicker :title="'ตั้งแต่วันที่'" :placeholder="'โปรดเลือก'" v-model="startDate" :disabled="dataLoading"/>
              </v-col>
               <v-col cols="12" md="2" >
                <DatePicker :title="'ถึงวันที่'" :placeholder="'โปรดเลือก'" v-model="endDate" :disabled="dataLoading"/>
              </v-col>
      </template>
      <template v-slot:right>
               <v-col cols="12" md="4" >
                <Select :title="'ชื่อฟอร์ม'" :placeholder="'โปรดเลือก'" :disabled="dataLoading" :items="formListItems" v-model="formId" item-text="name" return-object />
              </v-col>
              <v-col cols="12" md="2" >
    <v-btn large color="primary" class="mt-6" @click="getResult" :loading="dataLoading"><span class="text-title-2">เรียกดูข้อมูล</span></v-btn>
    </v-col>
      </template>
    </FilterBar>
    <v-row class="pl-5 pr-10 pt-5" v-else>
      <v-col cols="12">
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar, article, actions"
          height="100"
        ></v-skeleton-loader>
      </v-col>
      </v-row>
      <v-row class="pl-5 pr-10" v-if="series.length > 0">
        <v-col cols="4">
              <CountingCard :title="'เขียว'" :total="green" :img="'undraw_jogging_re_k28i.svg'" :color="'green'"/>
        </v-col>
        <v-col cols="4">
              <CountingCard :title="'เหลือง'" :total="yellow" :img="'undraw_warning_re_eoyh.svg'" :color="'yellow'"/>
        </v-col>
        <v-col cols="4">
              <CountingCard :title="'แดง'" :total="red" :img="'undraw_medicine_b-1-ol.svg'" :color="'red'"/>
        </v-col>
      </v-row>
      <v-row class="pl-5 pr-10" v-if="series.length > 0">
        <v-col cols="12">
       <OverallBarChart title="จำนวนผู้ถูกคัดกรองความดันโลหิต แยกตามหมู่บ้าน" :series="series" :label="label"/>
      </v-col>
      <template v-if="subdist.length > 0">
      <v-col cols="4" v-for="(item, index) in subdist" :key="index">
       <OverallBarChartStack :title="item.label" :series="item.series"/>
      </v-col>
    </template>
      </v-row>
    </v-container>
  </template>

<script>
import TitleBar from '@/components/common/TitleBar'
import CountingCard from '@/components/analytic/CountingCard'
import Select from '@/components/common/Select'
import DatePicker from '@/components/common/DatePicker'
import FilterBar from '@/components/common/FilterBar'
import OverallBarChart from '@/components/general/OverallBarChart'
import OverallBarChartStack from '@/components/general/OverallBarChartStack'
import { getFormPromise, getSubDistrict, getBpLevel } from '@/api/'

export default {
  components: {
    TitleBar,
    CountingCard,
    // RankingCard,
    // ResultCard,
    DatePicker,
    FilterBar,
    Select,
    OverallBarChart,
    OverallBarChartStack
  },
  data () {
    return {
      formListItems: [
        { text: 'แบบฟอร์มคัดกรองความดันโลหิต', value: 'name' }
      ],
      selected: { text: 'แบบฟอร์มคัดกรองความดันโลหิต', value: 'name' },
      headers: [
        { text: 'ชื่อ', value: 'name', class: 'primary lighten-5' },
        { text: 'ประเภท', value: 'type', class: 'primary lighten-5' },
        { text: 'ประจำปี', value: 'year', class: 'primary lighten-5' },
        { text: 'วันที่สร้าง', value: 'createdate', class: 'primary lighten-5' },
        { text: 'ผู้สร้าง', value: 'createby', class: 'primary lighten-5' },
        { text: 'จำนวนคนตอบ', value: 'total', class: 'primary lighten-5' },
        { text: 'สถานะ', value: 'status', class: 'primary lighten-5' },
        { text: 'Action', value: 'action', class: 'primary lighten-5' }
      ],
      subdistrictItems: [],
      loading: true,
      rankingItems: [],
      rankingSelfReportItems: [],
      totalMember: 0,
      totalCollector: 0,
      formType: [],
      status: [true],
      items: [],
      year: [String(new Date().getFullYear())],
      totalPatient: 0,
      startDate: null,
      endDate: null,
      dataLoading: false,
      subdistrict: {},
      formId: null,
      green: 0,
      yellow: 0,
      red: 0,
      series: [],
      subdist: [],
      preLoad: true
    }
  },
  methods: {
    getResult () {
      this.dataLoading = true
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      getBpLevel({
        tenantId: tenant.tenantId,
        siteId: site.siteId,
        formId: this.formId.formId,
        formTypeId: this.formId.formTypeId,
        subdistrict: this.subdistrict.subdistrict,
        startDate: this.startDate,
        endDate: this.endDate
      }, message => {
        if (message.data.code === 1) {
          if (message.data.result) {
            let green = 0
            let yellow = 0
            let red = 0
            const greenSeries =
              {
                name: 'เขียว',
                data: [],
                color: '#388E3C'
              }
            const yellowSeries =
              {
                name: 'เหลือง',
                data: [],
                color: '#FBC02D'
              }
            const redSeries =
              {
                name: 'แดง',
                data: [],
                color: '#D32F2F'
              }
            const subdist = []

            message.data.result.sort((a, b) => {
              const nameA = Number(a.addressVillage)
              const nameB = Number(b.addressVillage)
              if (nameA < nameB) {
                return -1
              }
              if (nameA > nameB) {
                return 1
              }
              // names must be equal
              return 0
            }).forEach((element) => {
              green += element.mGreen
              green += element.fGreen
              yellow += element.mYellow
              yellow += element.fYellow
              red += element.mRed
              red += element.fRed
              greenSeries.data.push({ x: `หมู่ ${element.addressVillage}`, y: element.mGreen + element.fGreen })
              yellowSeries.data.push({ x: `หมู่ ${element.addressVillage}`, y: element.mYellow + element.fYellow })
              redSeries.data.push({ x: `หมู่ ${element.addressVillage}`, y: element.mRed + element.fRed })

              subdist.push({
                label: `จำนวนผู้คัดกรอง หมู่ ${element.addressVillage}`,
                series: [
                  {
                    name: 'ชาย',
                    data: [{ x: 'เขียว', y: element.mGreen }, { x: 'เหลือง', y: element.mYellow }, { x: 'แดง', y: element.mRed }]
                  },
                  {
                    name: 'หญิง',
                    data: [{ x: 'เขียว', y: element.fGreen }, { x: 'เหลือง', y: element.fYellow }, { x: 'แดง', y: element.fRed }]
                  }
                ]
              })
            })
            this.green = green
            this.yellow = yellow
            this.red = red
            this.series = []
            this.series.push(greenSeries)
            this.series.push(yellowSeries)
            this.series.push(redSeries)
            this.subdist = subdist
          }
        }
        this.dataLoading = false
      }, error => {
        console.log(error)
        this.dataLoading = false
      })
    },
    async fetchData () {
      this.preLoad = true
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      try {
        // this.getForm()
        const resp = await getFormPromise({
          tenantId: tenant.tenantId,
          siteId: site.siteId,
          offset: 0,
          limit: 100,
          formTypeId: this.buildParam('formTypeId', ['']),
          year: this.buildParam('year', ['']),
          active: this.buildParam('active', [true])
        })
        if (resp.data.code === 1) {
          this.formListItems = resp.data.result.items
          this.formId = resp.data.result.items[0]
        }
        const subdist = await getSubDistrict({ district: site.district })
        if (subdist.data.code === 1) {
          this.subdistrictItems = subdist.data.result
          this.subdistrict = subdist.data.result[0]
        }
      } catch (error) {

      } finally {
        this.preLoad = false
        this.$gtag.set({
          user_id: this.$auth.user.uniqueId
        })
      }
    },
    buildArrayItem (key, data) {
      const ret = []
      for (let i = 0; i < data.length; i++) {
        ret.push(data[i][key])
      }
      return ret
    },
    buildParam (param, data) {
      let ret = ''
      for (let i = 0; i < data.length; i++) {
        if (i < 1) {
          ret += param + '=' + data[i]
        } else {
          ret += '&' + param + '=' + data[i]
        }
      }
      return ret
    }
  },
  created () {
    this.startDate = this.$dayjs().subtract(3, 'month').format('YYYY-MM-DD')
    this.endDate = this.$dayjs().format('YYYY-MM-DD')
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>
