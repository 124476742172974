<template>
   <v-card outlined>
                        <v-list>
                        <v-list-item>
    <div v-if="index===0" class="pa-5 text-h5 natural--text text--darken-4"><v-icon color="warning">mdi-crown</v-icon> {{index+1}} </div>
    <div v-else-if="index===1" class="pa-5 text-h5 natural--text text--darken-4"><v-icon color="natural">mdi-crown</v-icon>  {{index+1}} </div>
     <div v-else-if="index===2" class="pa-5 text-h5 natural--text text--darken-4"><v-icon color="error lighten-2">mdi-crown</v-icon>  {{index+1}} </div>
         <div v-else class="pa-5 text-h5 natural--text text--darken-4"><v-icon></v-icon> {{index+1}} </div>
    <v-list-item-avatar>
            <v-img :src="require('@/assets/' + ( ((index+1) % 6)+1) + '.svg')"></v-img>
          </v-list-item-avatar>
              <v-list-item-content class="pl-5">
            <v-list-item-title class="text-h6 natural--text text--darken-4" v-text="name"></v-list-item-title>
            <v-list-item-subtitle v-text="position"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
              <v-list-item-action-text class="text-h6 natural--text text--darken-4">{{total}}</v-list-item-action-text>
             <v-icon></v-icon>
          </v-list-item-action>
                         </v-list-item>
                        </v-list>
</v-card>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>
   .v-sheet.v-card {
    border-radius: 8px;
  }
</style>
