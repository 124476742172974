<template>
   <v-card>
        <v-card-title class="text-h5 font-weight-bold natural--text text--darken-4">{{title}}</v-card-title>
          <v-card-text class="text-center">
            <apexchart type="bar" height="350" width="100%" :options="chartOptions" :series="series"></apexchart>
            </v-card-text>
        </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    series: {
      type: Array
    }
  },
  data () {
    return {
      // series: [{
      //   name: 'ชาย',
      //   data: [300, 160, 90]
      // }, {
      //   name: 'หญิง',
      //   data: [400, 170, 80]
      // }],
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top',
              total: {
                enabled: true
              }
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          // categories: ['เขียว', 'เหลือง', 'แดง']
        },
        yaxis: {
          title: {
            // text: 'เกณฑ์การคัดกรอก'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return ' ' + val + ' คน'
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
  .v-sheet.v-card {
    border-radius: 16px;
    /* border-color: #ecaf00 !important; */
  }
</style>
