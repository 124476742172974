<template>
  <v-container fluid>
    <TitleBar :title="'ยินดีต้อนรับสู่ VWELL'" :subtitle="''">
    </TitleBar>
    <v-row class="pl-5 pr-10">
      <v-col cols="8">
        <v-row>
          <v-col cols="6">
            <CountingCard :title="'ผู้ใช้ในระบบทั้งหมด'" :total="totalMember" :img="'user.svg'"/>
          </v-col>
           <v-col cols="6">
            <CountingCard :title="'เจ้าหน้าที่คัดกรอง'" :total="totalCollector" :img="'officer.svg'"/>
          </v-col>
            <v-col cols="6">
            <CountingCard :title="'แพทย์'" :total="0" :img="'doctor.svg'"/>
          </v-col>
            <v-col cols="6">
            <CountingCard :title="'ผู้ป่วย'" :total="totalPatient" :img="'nurse.svg'"/>
          </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
           <ResultCard :items="items"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
       <RankingCard :ranking="rankingItems" title="10 อันดับเจ้าหน้าที่คัดกรองสูงสุด"/>
       <v-row class="mt-4">
         <v-col></v-col>
       </v-row>
       <RankingCard :ranking="rankingSelfReportItems" title="10 อันดับผู้รายงานข้อมูลสูงสุด"/>
      </v-col>
    </v-row>
    <!-- <v-row class="pl-5 pr-10">
      <v-col cols="12">
     <OverallBarChart title="จำนวนผู้ถูกคัดกรองความดันโลหิต แยกตามหมู่บ้าน"/>
    </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import TitleBar from '@/components/common/TitleBar'
import CountingCard from '@/components/general/CountingCard'
import RankingCard from '@/components/general/RankingCard'
import ResultCard from '@/components/general/ResultCard'
// import OverallBarChart from '@/components/general/OverallBarChart'
import { getReportTopScreening, getReportTopSelfReport, getSiteUser, getCollector, getSiteFormType, getForm, getPatient } from '@/api/'

export default {
  components: {
    TitleBar,
    CountingCard,
    RankingCard,
    ResultCard
    // OverallBarChart
  },
  data () {
    return {
      headers: [
        { text: 'ชื่อ', value: 'name', class: 'primary lighten-5' },
        { text: 'ประเภท', value: 'type', class: 'primary lighten-5' },
        { text: 'ประจำปี', value: 'year', class: 'primary lighten-5' },
        { text: 'วันที่สร้าง', value: 'createdate', class: 'primary lighten-5' },
        { text: 'ผู้สร้าง', value: 'createby', class: 'primary lighten-5' },
        { text: 'จำนวนคนตอบ', value: 'total', class: 'primary lighten-5' },
        { text: 'สถานะ', value: 'status', class: 'primary lighten-5' },
        { text: 'Action', value: 'action', class: 'primary lighten-5' }
      ],
      loading: true,
      rankingItems: [],
      rankingSelfReportItems: [],
      totalMember: 0,
      totalCollector: 0,
      formType: [],
      status: [true],
      items: [],
      year: [String(new Date().getFullYear())],
      totalPatient: 0
    }
  },
  methods: {
    getReportTopSelfReport () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      getReportTopSelfReport({ tenantId: tenant.tenantId, siteId: site.siteId }, message => {
        if (message.data.code === 1) {
          this.rankingSelfReportItems = message.data.result
          // console.log(message.data.result)
        }
      }, error => {
        console.log(error)
      })
    },
    getReportTopScreening () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      getReportTopScreening({ tenantId: tenant.tenantId, siteId: site.siteId }, message => {
        if (message.data.code === 1) {
          this.rankingItems = message.data.result
          // console.log(message.data.result)
        }
      }, error => {
        console.log(error)
      })
    },
    getSiteUser () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      getSiteUser(
        {
          tenantId: tenant.tenantId,
          siteId: site.siteId,
          offset: 0,
          limit: 200
        },
        (message) => {
          if (message.data.code === 1) {
            if (message.data.result) {
              this.totalMember = message.data.result.itemsLength
            } else {
              this.totalMember = 0
            }
          }
        },
        (error) => {
          console.log(error)
        }
      )
    },
    getSiteCollector () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      getCollector({
        tenantId: tenant.tenantId,
        siteId: site.siteId,
        offset: 0,
        limit: 100
      }, message => {
        if (message.data.code === 1) {
          if (message.data.result) {
            this.totalCollector = message.data.result.itemsLength
          } else {
            this.totalCollector = 0
          }
        }
        this.loading = false
      }, error => {
        this.loading = false
        console.log(error)
      })
    },
    getPatient () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      getPatient({
        tenantId: tenant.tenantId,
        siteId: site.siteId,
        offset: 0,
        limit: 5000,
        sortBy: '',
        sortDesc: ''
      }, message => {
        if (message.data.code === 1) {
          this.totalPatient = message.data.result.itemsLength
          // console.log(message.data.result.items.length)
        } else {
          this.totalPatient = 0
        }
      }, error => {
        console.log(error)
      })
    },
    async fetchData () {
      this.getReportTopScreening()
      this.getReportTopSelfReport()
      this.getSiteUser()
      this.getSiteCollector()
      this.getPatient()
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      try {
        const resp = await getSiteFormType({
          tenantId: tenant.tenantId,
          siteId: site.siteId,
          offset: 0,
          limit: 100,
          sortBy: 'formTypeName',
          sorDesc: 0
        })
        if (resp.data.code === 1) {
          this.formType = this.buildArrayItem('formTypeId', resp.data.result.items)
        }
      } catch (error) {

      } finally {
        getForm({
          tenantId: tenant.tenantId,
          siteId: site.siteId,
          offset: 0,
          limit: 100,
          formTypeId: (Array.isArray(this.formType)) ? this.buildParam('formTypeId', this.formType) : this.buildParam('formTypeId', [this.formType]),
          year: this.buildParam('year', this.year),
          active: this.buildParam('active', this.status)// this.status
        }, message => {
          if (message.data.code === 1) {
            if (message.data.result) {
              this.items = message.data.result.items
            } else {
              this.items = []
            }
          }
        }, error => {
          console.log(error)
        })
        this.$gtag.set({
          user_id: this.$auth.user.uniqueId
        })
      }
    },
    buildArrayItem (key, data) {
      const ret = []
      for (let i = 0; i < data.length; i++) {
        ret.push(data[i][key])
      }
      return ret
    },
    buildParam (param, data) {
      let ret = ''
      for (let i = 0; i < data.length; i++) {
        if (i < 1) {
          ret += param + '=' + data[i]
        } else {
          ret += '&' + param + '=' + data[i]
        }
      }
      return ret
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>
