<template>
   <v-card>
          <v-card-text>
           <v-row>
             <v-col cols="4">
               <v-img width="158" contain :src="require('@/assets/'+ img)"/>
             </v-col>
              <v-col cols="8" align-self="center">
               <div :class="`text-h5 ${color}--text text--darken-2`"> {{title}} </div>
               <div v-if="total===0" class="text-h2 natural--text"> {{total}} คน </div>
               <div v-else class="text-h3 natural--text text--darken-4"> {{total}} คน </div>
              </v-col>
           </v-row>
          </v-card-text>
        </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 0
    },
    img: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  }

}
</script>

<style scoped>
  .v-sheet.v-card {
    border-radius: 16px;
  }
</style>
